/*jshint esversion: 6 */

// import axios from 'axios';


export function fetchDataFunc(bodyData, errorDiv, phpUrl) {
    // let url = phpUrl;
    let serverUrl;
    if (process.env.NODE_ENV === 'development') {
        serverUrl = process.env.VUE_APP_URL;
    } else {
        serverUrl = process.env.VUE_APP_URL_BUILD;
    }

    let base64 = require('base-64');
    let username = 'typAdmin';
    let password = 's+4kCbydvC';
    let headers = new Headers();
    headers.append('Authorization', 'Basic' + base64.encode(username + ":" + password));

    return fetch(`${serverUrl}${phpUrl}`, {
        method: 'POST',
        body: bodyData,
    })
    .then((response) => {
        if (!response.ok) {
            var error1 = {'error': response.statusText, 'errorCode': 'main'};
            throw new Error(error1);
        }
        return response.json();
    })
    .then((result) => {
        if (result.processResponse === true) {
            return result.message;
        } else {
            throw new Error(result.message);
        }
    })
    .catch((error) => {
        document.getElementById(errorDiv).innerHTML = error;
        return false;
    })

    /*
    let username = 'typAdmin';
    let password = 's+4kCbydvC';
    const usernamePasswordBuffer = Buffer.from(username + ':' + password);
    const base64data = usernamePasswordBuffer.toString('base64');

    // let basicAuth = 'Basic ' + btoa(username + ':' + password);

    return axios.post(`${serverUrl}${phpUrl}`, bodyData,  
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization' : `Basic ${base64data}`
            }
        })
        .then(function(response){
            if (response.data.processResponse === true) {
                return response.data.message;
            } else {
                throw new Error(response.data.message);
            }
        })
        .catch(function(error) {  
            document.getElementById(errorDiv).innerHTML = error;
            console.log('Error on Authentication');
            return false;
        });
        */
}

export function flattenContent(content) {
    var returnText;
    returnText = content.join(" ");
    return returnText;
}

export function showImage(imageName, that) {
    that.$root.openLayerPix(imageName);
}

export function startEventListenerFunc(element) {
    var doit;
    window.addEventListener('resize', () => {
        clearTimeout(doit);
        doit = setTimeout(function() {detectWindowSizeFunc(element)}, 100);
    });

    window.addEventListener('orientationchange', function() {
        detectWindowSizeFunc(element);
    });
}

export function detectWindowSizeFunc(element) {
    var that = element;
    if (window.innerWidth <= 500) {
        that.windowSize = 'mobile';
    } else if ((window.innerWidth >501) && (window.innerWidth <= 1200)) {
        that.windowSize = 'tablett';
    } else {
        that.windowSize = 'desktop';
    }
}