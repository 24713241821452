/*jshint esversion: 6 */

//redefine console.log
//console.log = function() {};

//css
import './../css/style.scss';
import './../css/fonts.scss';



//language
if (!sessionStorage.getItem('language')) {
    if (navigator.language.indexOf("de") > -1) {
        sessionStorage.setItem('language', 'DEU');
    } else {
        sessionStorage.setItem('language', 'ENG');
    }
} 